<template>
  <el-dialog
    :visible.sync="visible"
    title="移动到分类"
    :close-on-click-modal="false"
    class="yt-dialog yt-dialog-default"
    :before-close="close"
    @closed="closed"
  >
    <div class="moveClass">
      <el-tree @node-click="lessonMove" :data="courseCategoryData" ref="tree"> </el-tree>
    </div>
    <div class="footer" slot="footer">
      <el-button @click="moveCancel" class="button">取消</el-button>
      <el-button type="primary" :loading="loading" @click="classMove" class="button">确认</el-button>
    </div>
  </el-dialog>
</template>

<script>
import courseApi from '@api/course'
export default {
  name: 'CourseMoveDialog',
  data() {
    return {
      courseCategoryData: [],
      visible: false,
      moveToCategoryId: 0,
      moveCourseId: [],
      loading: false
    }
  },
  methods: {
    open(data, moveCourseId) {
      this.courseCategoryData = data
      this.visible = true
      this.moveCourseId = moveCourseId
    },
    classMove() {
      if (this.moveToCategoryId === 0) {
        this.$message.error('请选择课程分类')
        return
      }
      this.loading = true
      courseApi
        .getGroupMove(this.moveToCategoryId, this.moveCourseId)
        .then(res => {
          if (res.code === 0) {
            this.$message.success('课程移动成功')
            this.$emit('refresh')
            this.visible = false
          } else {
            this.loading = false
          }
        })
        .catch(err => {
          this.loading = false
        })
    },
    moveCancel() {
      this.visible = false
    },
    lessonMove(data) {
      this.moveToCategoryId = data.value
    },
    closed() {
      this.moveCourseId = []
      this.moveToCategoryId = 0
      this.loading = false
    },
    close(done) {
      done()
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/theme/tableDropdownMenu';
</style>
