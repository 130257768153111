import config from '../config/config'
import { axios } from './index'

let courseCategoryUrl = config.baseUrl + '/course/api/v1/course/category'

export default {
  //获取企业简易课程分类树
  getCategoryTreeByOrganizationId() {
    return axios.get(`${courseCategoryUrl}/simple/tree`)
  },
  //新建课程分类
  add(payload) {
    return axios.post(`${courseCategoryUrl}/add`, payload)
  },
  //修改课程分类
  update(payload) {
    return axios.post(`${courseCategoryUrl}/modify`, payload)
  },
  //删除课程分类
  delete(payload) {
    return axios.post(`${courseCategoryUrl}/delete?categoryId=${payload}`)
  }
}
