<template>
  <div class="yt-main">
    <div class="yt-breadcrumb-wrapper">
      <el-breadcrumb class="yt-breadcrumb">
        <el-breadcrumb-item>课程管理</el-breadcrumb-item>
        <el-breadcrumb-item>课程列表</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="yt-header">
      <el-menu class="yt-menu" :default-active="activeIndex" mode="horizontal" @select="selectMenu">
        <el-menu-item index="1">学校</el-menu-item>
        <el-menu-item index="0">平台</el-menu-item>
      </el-menu>
      <div class="yt-header-toolbar">
        <el-input v-model="keyword" placeholder="通过名称搜索课程" class="yt-search" @keydown.enter.native="reSearchCourse">
          <i slot="suffix" class="el-input__icon el-icon-search" @click="reSearchCourse" />
        </el-input>
        <el-button v-if="activeIndex !== '0'" type="primary" size="small" @click="toCreate">
          <div>
            <i class="el-icon-circle-plus-outline" />
            <p>新建课程</p>
          </div>
        </el-button>
        <el-button v-if="activeIndex !== '0'" size="small" @click="toDelete(false)">
          <div>
            <YTIcon :href="'#icon-shanchu'" />
            <p>删除课程</p>
          </div>
        </el-button>
      </div>
    </div>
    <div
      class="yt-container"
      :class="{
        'yt-container-flex': activeIndex === '1',
        'is-pack-up': isPackUp
      }"
    >
      <div class="yt-content-tree has-footer" v-if="activeIndex === '1'">
        <template v-if="isPackUp">
          <YTIcon class="pack-up-button" :href="'#icon-zhankai'" @click="isPackUp = false" />
        </template>
        <template v-else>
          <div class="header">
            <YTIcon class="pack-up-button" :href="'#icon-shouqi1'" @click="isPackUp = true" />
            <YTIcon style="margin: 0 5px 0 19px; font-size: 20px" :href="'#icon-fenlei1'" />
            <p>课程分类</p>
          </div>
          <div class="tree has-tool">
            <el-tree ref="category-tree" :data="courseCategoryData" node-key="value" :highlight-current="true" @node-click="handleNodeClick">
              <div slot-scope="{ node, data }" class="tree-node">
                <el-tooltip effect="dark" :content="node.label" :disabled="node.label.length < 10" placement="top-start">
                  <div class="el-tree-node__label">{{ node.label }}</div>
                </el-tooltip>
                <div v-if="data.value" class="tools">
                  <span class="tool-button" @click.stop="toCreateCategory(data)">添加</span>
                  <span class="tool-button" @click.stop="toUpdateCategory(data)">修改</span>
                  <span class="tool-button" @click.stop="toDeleteCategory(data)">删除</span>
                </div>
              </div>
            </el-tree>
          </div>
          <div class="footer">
            <el-button style="width: 138px" size="small" @click="toCreateCategory(false)">
              <div>
                <i class="el-icon-circle-plus-outline" />
                <p>新建课程分类</p>
              </div>
            </el-button>
          </div>
        </template>
      </div>
      <div class="yt-content">
        <el-table
          ref="courseTable"
          :data="courseList"
          v-loading="courseLoading"
          element-loading-text="加载中"
          class="yt-table"
          height="calc(100% - 35px)"
          @select="selectCourse"
          @select-all="selectCourse"
        >
          <el-table-column :class-name="activeIndex !== '0' ? 'el-table-column--selection' : 'close-checkbox'" type="selection" width="31" />
          <el-table-column label="课程名称" prop="name" show-overflow-tooltip />
          <el-table-column v-if="activeIndex !== '0'" label="创建者" prop="creatorName" show-overflow-tooltip />
          <el-table-column v-if="activeIndex !== '0'" label="创建时间" show-overflow-tooltip>
            <template v-if="activeIndex !== '0'" slot-scope="scope">
              {{ $formatTime(scope.row.createTime, 'yyyy-MM-dd hh:mm') }}
            </template>
          </el-table-column>
          <el-table-column label="描述" prop="description" show-overflow-tooltip />
          <el-table-column label="操作" width="120px">
            <template slot-scope="scope">
              <span class="tool-button" @click="gotoCourseDetail(scope.row)">查看</span>
              <template v-if="activeIndex !== '0'">
                <span class="tool-button" @click="toMove(scope.row)">移动</span>
                <el-dropdown placement="bottom-start" @command="handleCommand($event, scope.row)">
                  <span class="tool-button">更多</span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item command="update">修改</el-dropdown-item>
                    <el-dropdown-item command="delete">删除</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </template>
            </template>
          </el-table-column>
          <div slot="empty" id="empty-slot">
            <template v-if="!courseLoading">
              <div class="emptyRadar">
                <YTIcon :href="'#icon-zanwushuju'" style="font-size: 100px"></YTIcon>
              </div>
            </template>
          </div>
        </el-table>
        <Page
          class="yt-page"
          :class="'yt-page-' + (total.toString().length > 1 ? total.toString().length : 2)"
          :total="total"
          :current="pageNum + 1"
          :page-size-opts="[10, 20, 40, 100]"
          :page-size="pageSize"
          @on-change="changePage"
          @on-page-size-change="changePageSize"
          show-elevator
          show-sizer
          show-total
        />
      </div>
    </div>
    <CourseCategoryCUDialog ref="categoryCUDialog" @close="toCloseCategoryCu" />
    <ConfirmDialog ref="categoryDeleteDialog" :title="'删除课程分类'" @on-ok="deleteCategory">
      <div style="width: 219px; margin: 0 auto;">
        <YTIcon :href="'#icon-jingshi'" style="font-size: 18px;" />
        是否确认删除该课程分类，删除后将不可恢复
      </div>
    </ConfirmDialog>
    <ConfirmDialog ref="courseDeleteDialog" :title="'删除课程'" @on-ok="deleteCourse">
      <div style="width: 219px; margin: 0 auto;">
        <YTIcon :href="'#icon-jingshi'" style="font-size: 18px;" />
        是否确认删除该课程，删除后将不可恢复
      </div>
    </ConfirmDialog>
    <CourseCUDialog ref="courseCUDialog" @refresh="searchCourse"></CourseCUDialog>
    <CourseMoveDialog ref="courseMoveDialog" @refresh="searchCourse"></CourseMoveDialog>
  </div>
</template>

<script>
import YTIcon from '@components/common/YTIcon'
import CourseCategoryCUDialog from '@/components/common/dialog/CourseCategoryCUDialog'
import CourseCUDialog from '@/components/common/dialog/CourseCUDialog'
import ConfirmDialog from '@/components/common/dialog/ConfirmDialog'
import courseApi from '@api/course'
import courseCategoryApi from '@/api/courseCategory'
import CourseMoveDialog from '@/components/common/dialog/CourseMoveDialog'
export default {
  name: 'CourseList',
  components: {
    YTIcon,
    CourseCategoryCUDialog,
    ConfirmDialog,
    CourseCUDialog,
    CourseMoveDialog
  },
  data() {
    return {
      activeIndex: '1',
      keyword: '',
      categoryId: 0,
      isPackUp: false, //课程分类是否收起
      courseCategoryData: [
        {
          label: '所有课程',
          value: 0
        }
      ], //课程分类树
      courseList: [],
      courseLoading: false,
      total: 0,
      pageNum: 0,
      pageSize: 10,
      courseSelection: [],
      parentData: {}, //存储添加子节点的父节点
      deleteData: {}, //存储要删除节点的数据
      //后期需要调整的变量
      addCourse: false,
      postId: 0,
      edit: false,
      moveClass: false,
      moveToCategoryId: 0,
      moveCourseId: []
    }
  },
  mounted() {
    this.getCategory()
    this.searchCourse()
  },
  methods: {
    searchCourse() {
      this.courseLoading = true
      if (this.activeIndex === '1') {
        let payload = {
          categoryId: this.categoryId,
          keyword: this.keyword
        }
        courseApi
          .searchForOrganization(payload, this.pageNum, this.pageSize)
          .then(res => {
            if (res.res.total > 0 && Math.ceil(res.res.total / this.pageSize) < this.pageNum + 1) {
              this.pageNum = Math.ceil(res.res.total / this.pageSize) - 1
              this.searchCourse()
            } else {
              this.courseList = res.res.data
              this.total = res.res.total
            }
          })
          .finally(() => {
            this.courseLoading = false
          })
      } else {
        courseApi
          .searchForPlatform(this.keyword, this.pageNum, this.pageSize)
          .then(res => {
            if (res.res.total > 0 && Math.ceil(res.res.total / this.pageSize) < this.pageNum + 1) {
              this.pageNum = Math.ceil(res.res.total / this.pageSize) - 1
              this.searchCourse()
            } else {
              this.courseList = res.res.data
              this.total = res.res.total
            }
          })
          .finally(() => {
            this.courseLoading = false
          })
      }
    },
    reSearchCourse() {
      this.pageNum = 0
      this.searchCourse()
    },
    getCategory() {
      courseCategoryApi.getCategoryTreeByOrganizationId().then(res => {
        this.courseCategoryData = this.courseCategoryData.concat(res.res)
        this.setCurrentKey()
      })
    },
    selectMenu(index) {
      this.activeIndex = index
      this.keyword = ''
      this.courseList = []
      this.reSearchCourse()
      if (index === '1') {
        this.setCurrentKey()
      }
    },
    toCreate() {
      this.$refs.courseCUDialog.open(this.categoryId)
    },
    toDelete(data) {
      if (data) {
        this.courseSelection = [data.courseId]
      } else {
        if (this.courseSelection.length === 0) {
          this.$message.warning('请先勾选要删除的课程')
          return
        }
      }
      this.$refs.courseDeleteDialog.open()
    },
    deleteCourse() {
      courseApi.delCourses(this.courseSelection).then(res => {
        if (res.res === true) {
          this.$message.success('删除成功')
          this.searchCourse()
        }
      })
    },
    handleNodeClick(data) {
      this.categoryId = data.value
      this.reSearchCourse()
    },
    setCurrentKey(value) {
      this.$nextTick(() => {
        this.$refs['category-tree'].setCurrentKey(value ? value : this.categoryId)
      })
    },
    toCreateCategory(data) {
      if (data) {
        this.parentData = data
        this.$nextTick(() => {
          this.$refs.categoryCUDialog.initData(data, true)
        })
      }
      this.$refs.categoryCUDialog.open(true)
    },
    toUpdateCategory(data) {
      this.$nextTick(() => {
        this.$refs.categoryCUDialog.initData(data, false)
      })
      this.$refs.categoryCUDialog.open(false)
    },
    toCloseCategoryCu(data) {
      if (this.$refs.categoryCUDialog.mode) {
        this.$refs['category-tree'].append(data, this.parentData.hasOwnProperty('parentId') ? this.parentData : null)
      } else {
        this.updateTreeNode(this.courseCategoryData, data)
      }
      this.parentData = {}
    },
    updateTreeNode(treeNode, data) {
      for (let item of treeNode) {
        if (item.value === data.value) {
          this.$set(item, 'label', data.label)
          this.$set(item, 'description', data.description)
          this.$forceUpdate()
          break
        }
        if (item.hasOwnProperty('children')) {
          this.updateTreeNode(item.children, data)
        }
      }
    },
    toDeleteCategory(data) {
      this.deleteData = data
      this.$refs.categoryDeleteDialog.open()
    },
    deleteCategory() {
      courseCategoryApi.delete(this.deleteData.value).then(res => {
        if (res.res === true) {
          this.$message.success('删除课程分类成功')
          this.$refs['category-tree'].remove(this.deleteData)
        }
      })
    },
    selectCourse(selection) {
      this.courseSelection = selection.map(item => {
        return item.courseId
      })
    },
    handleCommand(command, row) {
      if (command === 'update') {
        let editForm = Object.assign({}, row)
        this.$refs.courseCUDialog.updateCourse(editForm, false)
      } else if (command === 'delete') {
        this.toDelete(row)
      }
    },
    changePage(pageNum) {
      this.pageNum = pageNum - 1
      this.searchCourse()
    },
    changePageSize(pageSize) {
      this.pageSize = pageSize
      this.searchCourse()
    },
    cancelAdd() {
      this.addCourse = false
    },
    toMove(row) {
      this.moveCourseId = [row.courseId]
      this.$refs.courseMoveDialog.open(this.courseCategoryData, this.moveCourseId)
    },
    gotoCourseDetail(row) {
      this.$router.push({ name: 'courseDetail', params: { courseId: row.courseId } })
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/theme/tableDropdownMenu';
.emptyRadar {
  position: relative;
  top: 70px;
  text-align: center;
  &:after {
    content: '暂无数据';
    color: #666666;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: -20px;
  }
}
</style>
